import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BsFilterLeft } from 'react-icons/bs';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { BiSearch } from 'react-icons/bi';
import { SET_TRAINING_FILTERS } from '../../actions/types';
import FilterList from './partials/FilterList';

import styles from './filters.module.scss';

export default function Filters({ isDisabled }) {
  const { trainingsFilters, lists, trainingsList } = useSelector((state) => state.trainings);
  const dispatch = useDispatch();

  const [filterIsOpen, setFiltersIsOpen] = useState(false);
  const [search, setSearch] = useState();
  const [plannedDatesList, setPlannedDatesList] = useState();

  const therapeuticAreas = lists?.therapeuticAreas.map((d) => ({ value: d._id, label: d.name }))
  || [];
  const divisions = lists?.divisions || [];
  const archivedList = [{ value: 'false', label: 'En cours' }, { value: 'true', label: 'Archivée' }];
  const drugsList = lists?.drugs?.map((d) => ({ value: d._id, label: d.name })) || [];
  const validityYearsList = trainingsList?.map((t) => new Date(t.validityDate).getFullYear())
    .filter((val, index, self) => self.indexOf(val) === index)
    .sort((a, b) => b - a)
    .map((d) => ({ value: d, label: d }));

  function getClassNames() {
    let classNames = styles.filters;
    if (isDisabled) {
      classNames += ` ${styles.disabled}`;
      return classNames;
    }
    if (filterIsOpen) {
      classNames += ` ${styles.isOpen}`;
    }
    return classNames;
  }

  function handleChangeFilters(key, value) {
    dispatch({
      type: SET_TRAINING_FILTERS,
      payload: { ...trainingsFilters, [key]: value },
    });
  }

  useEffect(() => {
    if (trainingsList?.length > 0) {
      let planned = [];
      trainingsList?.forEach((t) => {
        t.participants.forEach((p) => {
          planned.push(new Date(p.date).getFullYear());
        });
      });
      planned = planned.filter((val, index, self) => self.indexOf(val) === index)
        .sort((a, b) => b - a)
        .map((d) => ({ value: d, label: d }));
      setPlannedDatesList(planned);
    }
  }, [trainingsList]);

  return (
    <div className={getClassNames()}>
      <button className={styles['btn-filters']} onClick={() => setFiltersIsOpen(!filterIsOpen)}>
      {filterIsOpen ? <RiArrowLeftSLine size={28} color={'#FFFFFF'}/> : <BsFilterLeft size={30} color={'#FFFFFF'}/> }
      </button>
      {filterIsOpen
        && <>

          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ''}`}>
            <input
              value={search || '' }
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Rechercher"
              />
            <button
              className={styles['btn-search']}
              onClick={() => handleChangeFilters('search', search || '')}>
              <BiSearch size={18} color={'#FFFFFF'}/>
            </button>
          </div>

          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ''}`}>
            <FilterList
              name={'Départements'}
              styles={styles}
              list={divisions}
              activeList={trainingsFilters.divisions}
              setActiveList={(val) => handleChangeFilters('divisions', val)}
              />
          </div>

          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ''}`}>
            <FilterList
              name={'Aires thérapeutique'}
              styles={styles}
              list={therapeuticAreas}
              activeList={trainingsFilters.therapeuticAreas}
              setActiveList={(val) => handleChangeFilters('therapeuticAreas', val)}
              />
          </div>

          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ''}`}>
            <FilterList
              name={'Produits'}
              styles={styles}
              list={drugsList || []}
              activeList={trainingsFilters.drugs}
              setActiveList={(val) => handleChangeFilters('drugs', val)}
              />
          </div>

          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ''}`}>
            <FilterList
              name={'Année de validité'}
              styles={styles}
              list={validityYearsList || []}
              activeList={trainingsFilters.validityYearsList}
              setActiveList={(val) => handleChangeFilters('validityYearsList', val)}
              />
          </div>
          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ''}`}>
            <FilterList
              name={'Année prévue'}
              styles={styles}
              list={plannedDatesList || []}
              activeList={trainingsFilters.plannedDates}
              setActiveList={(val) => handleChangeFilters('plannedDates', val)}
              />
          </div>
          <div className={`${styles.filter} ${filterIsOpen ? `${styles.isOpen} ${styles.border}` : ''}`}>
            <FilterList
              name={'Statut'}
              styles={styles}
              list={archivedList || []}
              activeList={trainingsFilters.archived}
              setActiveList={(val) => handleChangeFilters('archived', val)}
              />
          </div>
          <div className={`filter ${filterIsOpen ? 'isOpen' : ''}`}>
          </div>
        </>
      }
    </div>
  );
}
