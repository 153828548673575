/* eslint-disable default-param-last */
import {
  ERROR_TRAININGS,
  GET_TRAININGS,
  GET_TRAINING,
  PUT_TRAINING,
  POST_TRAINING,
  DELETE_TRAINING,
  EXPORT_TRAINING,
  SET_TRAINING_FILTERS,
  SET_PLANNING_FILTERS,
  GET_TRAININGS_OPTIONS,
  POST_FILE,
  DELETE_FILE,
} from '../actions/types';

const initialState = {
  error: null,
  message: null,
  trainingsList: null,
  training: null,
  lists: null,
  exportData: null,
  trainingsFilters: {
    search: null,
    divisions: [],
    therapeuticAreas: [],
    drugs: [],
    validityYearsList: [],
    plannedDates: [],
    archived: [],
  },
};

export default function trainingsReducer(state = initialState, action) {
  switch (action.type) {
    case POST_FILE:
      return { ...state, file: action.payload };
    case GET_TRAININGS_OPTIONS:
      return { ...state, lists: action.payload };
    case SET_TRAINING_FILTERS:
      return { ...state, trainingsFilters: action.payload };
    case SET_PLANNING_FILTERS:
      return { ...state, planningFilters: action.payload };
    case EXPORT_TRAINING:
      return { ...state, exportData: action.payload };
    case DELETE_FILE:
      return { ...state, file: null };
    case ERROR_TRAININGS:
      return { ...state, error: action.payload };
    case POST_TRAINING:
      return { ...state, training: action.payload, file: null };
    case DELETE_TRAINING:
      return {
        ...state,
        trainingsList: state?.trainingsList.filter((v) => v._id !== action.payload),
      };
    case GET_TRAINING:
      return { ...state, training: action.payload };
    case PUT_TRAINING:
      return { ...state, training: action.payload, file: null };
    case GET_TRAININGS:
      return { ...state, trainingsList: action.payload };
    default:
      return { ...state };
  }
}
