import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import { RiFileDownloadFill } from 'react-icons/ri';
import ReactExport from 'react-export-excel';

// import { EXPORT_TRAINING } from '../../actions/types';

import Button from '../../lib/HooksFormFields/Button';

import styles from './ExportButton.module.scss';
import { exportTrainingsAction } from '../../actions/trainingsActions';
import { EXPORT_TRAINING } from '../../actions/types';

export default function ExportButton() {
  const { exportData } = useSelector((state) => state.trainings);
  const dispatch = useDispatch();
  const { ExcelFile } = ReactExport;
  const { ExcelSheet } = ReactExport.ExcelFile;
  const { ExcelColumn } = ReactExport.ExcelFile;

  function renderExcelToExport() {
    console.log(exportData.dataToExport);
    return (
        <ExcelFile
          filename={exportData.fileName}
          element={
            <Button
            handleClick={() => dispatch({
              type: EXPORT_TRAINING,
              payload: null,
            })}
            primary
            >
            TÉLÉCHARGER
          </Button>
          }
        >
          {exportData.dataToExport.map((sheet) => (
            <ExcelSheet key={sheet.sheetName} data={sheet.sheetData} name={sheet.sheetName}>
              {sheet.sheetHeader.map((c) => <ExcelColumn key={c} label={c} value={c}/>)}
            </ExcelSheet>
          ))}
        </ExcelFile>
    );
  }
  return (
    <div className={styles.container}>
        {exportData?.dataToExport
          ? <>
          {renderExcelToExport()}
          </>
          : <Button
          handleClick={() => exportTrainingsAction(dispatch)}
          primary
          >
          EXPORTER
        </Button>
      }
    </div>
  );
}
